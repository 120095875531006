.content-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  .container-loader {
    width: 100%;
    height: 100%;
    max-height: 400px;
    position: absolute;
    left: 0;
    z-index: 101;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 100;
    opacity: .4;
    background-color: #fff;
  }

  .loader {
    --c: no-repeat linear-gradient(#0057ff, #0057ff);
    background: var(--c), var(--c), var(--c),
    var(--c), var(--c), var(--c),
    var(--c), var(--c), var(--c);
    background-size: 16px 16px;
    animation: l32-1 1s infinite,
    l32-2 1s infinite;
  }

  @keyframes l32-1 {
    0%, 100% {
      width: 45px;
      height: 45px
    }
    35%, 65% {
      width: 65px;
      height: 65px
    }
  }

  @keyframes l32-2 {
    0%, 40% {
      background-position: 0 0, 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0, 50% 0, 50% 50%
    }
    60%, 100% {
      background-position: 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0, 50% 0, 0 0, 50% 50%
    }
  }
}