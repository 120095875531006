.add-modal-online-library {
  .ant-modal-content {
    border-radius: 40px;

    .create-button-online-library {
      border-radius: 16px;
    }
  }

  .ant-modal-close {
    padding: 20px;
    border: 1px solid #DAE0EE;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #004fff;
    }
  }
}