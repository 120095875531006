.content-file-exchange {
  background-color: #F1F5FE;
  min-height: 100vh;

  .title-document-file-exchange,
  .title-folder-file-exchange {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 5px;
  }

  .ant-statistic-content-value {
    color: #004FFF;
    font-weight: 700;
  }

  .container-file-exchange {
    margin: 0 auto;
    padding: 10px;
    width: 95%;

    .download-icon-file-exchange {
      display: inline-block;
      background: url("../../assets/document-download-library.svg") center center no-repeat;
      background-size: contain;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    .delete-icon-file-exchange {
      display: inline-block;
      background: url("../../assets/delete-library.svg") center center no-repeat;
      background-size: contain;
      width: 14px;
      height: 14px;
      margin-right: 10px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(326deg) brightness(104%) contrast(101%);
    }

    .header-file-exchange {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sector-file-exchange {
      width: 100%;
      border: 1px solid #dae0ee;
      border-radius: 40px;
      padding: 20px;
      background-color: #ffffff;
      margin-top: 20px;
    }

    .add-folder-file-exchange {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #004fff;
      border-radius: 16px;
      color: #ffffff;
      padding: 20px;
      font-weight: 600;
    }

    .delete-folder-file-exchange {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #d00000;
      border-radius: 16px;
      color: #ffffff;
      padding: 20px;
      font-weight: 600;
    }

    .back-btn-file-exchange {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      border-radius: 16px;
      background-color: #dae0ee;
      color: #004fff;
      border: none;
      padding: 20px;
    }

    .cancel-folder-file-exchange {
      font-weight: 600;
      border-radius: 16px;
      background-color: #dae0ee;
      color: #004fff;
      border: none;
    }

    .ant-upload-drag {
      background: none;
      border: 6px dashed #cad0de;
      padding: 20px;

      .ant-upload-btn {
        border: none;
      }

      .ant-upload-drag-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }

    .path-category-file-exchange {
      width: 50%;
    }
  }
}