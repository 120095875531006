.c-upload{
    .ant-upload-list{
        display: none;
    }
    .ant-upload{
        display: flex;
        gap: 8px;
        align-items: center;
        button{
            height: 35px;
            width: 35px;
            background: transparent;
            border: 1px solid #7449EF;
            color: #7449EF;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        p{
            margin-bottom: 0;
            &:first-child{
                color: #7449EF;
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 5px;
            }
            &:last-child{
                color: #C7C7C7;
                font-size: 10px;
                font-weight: 400;
            }
        }
    }
}