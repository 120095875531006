.container-useful {
  .sector-useful {
    .main-document-icon-useful {
      background: url("../../../assets/document-library.svg") center center no-repeat;
      background-size: contain;
      width: 40px;
      height: 35px;
      filter: brightness(0) saturate(100%) invert(44%) sepia(95%) saturate(2851%) hue-rotate(346deg) brightness(95%) contrast(87%);
    }

    .filename-useful {
      font-size: 14px;
      text-transform: uppercase;
      color: #e4572e;
      font-weight: 800;
    }
  }
}