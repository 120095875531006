.container-useful {
  .title-document-useful,
  .title-folder-useful {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 5px;
  }

  .sector-useful {
    .main-folder-icon-useful {
      background: url("../../../assets/folder-library.svg") center center no-repeat;
      background-size: contain;
      width: 40px;
      height: 35px;
      filter: brightness(0) saturate(100%) invert(44%) sepia(95%) saturate(2851%) hue-rotate(346deg) brightness(95%) contrast(87%);
    }

    .item-folder-useful {
      border: 1px solid #dae0ee;
      border-radius: 20px;
      padding: 20px;
    }

    .item-folder-useful:hover {
      outline: 2px solid #e4572e;
      transition: outline-color .3s ease;
    }

    .dropdown-useful {
      border: none;
      font-size: 30px;
      color: #e4572e;
      display: flex;
      align-items: flex-end;
      box-shadow: none;
    }
  }
}

.ant-dropdown-menu-title-content {
  .ant-typography {
    color: #192749;
    font-weight: 500;
  }
}

.edit-dropdown-useful {
  display: inline-block;
  background: url("../../../assets/edit-library.svg") center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(12%) sepia(9%) saturate(5992%) hue-rotate(191deg) brightness(97%) contrast(93%);
}

.info-dropdown-useful {
  display: inline-block;
  background: url("../../../assets/info-circle-online-library.svg") center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(12%) sepia(9%) saturate(5992%) hue-rotate(191deg) brightness(97%) contrast(93%);
}

.delete-dropdown-useful {
  display: inline-block;
  background: url("../../../assets/delete-library.svg") center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.download-dropdown-useful {
  display: inline-block;
  background: url("../../../assets/document-download-library.svg") center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(12%) sepia(9%) saturate(5992%) hue-rotate(191deg) brightness(97%) contrast(93%);
}