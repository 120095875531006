.c-button {
  // font-weight: 700;

  &.ant-btn {
    height: 45px;

    &-primary {
      background: #0057ff;
      color: #fff;
      &:disabled{
        background-color: #d9d9d9;
        color: rgba(0, 0, 0, .25);
      }
    }

    &-lg {
      height: 60px;
      font-size: 18px;
      border-radius: 5px;
    }
  }

  &_small {
    height: 100% !important;
    width: 100%;
  }

  &_left {
    .anticon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }
  }

  &_between {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .anticon {
      order: 1;
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;
      }

      &+span {
        margin-inline-start: 0;
      }
    }
  }
}
