.container-file-exchange {
  .title-document-file-exchange,
  .title-folder-file-exchange {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    margin-top: 5px;
  }

  .sector-file-exchange {
    .main-folder-icon-file-exchange {
      background: url("../../../assets/folder-library.svg") center center no-repeat;
      background-size: contain;
      width: 40px;
      height: 35px;
      filter: brightness(0) saturate(100%) invert(16%) sepia(93%) saturate(4617%) hue-rotate(223deg) brightness(102%) contrast(106%);
    }

    .item-folder-file-exchange {
      min-width: 180px;
      border: 1px solid #dae0ee;
      border-radius: 20px;
      padding: 20px;
    }

    .item-folder-file-exchange:hover {
      outline: 2px solid #004fff;
      transition: outline-color .3s ease;
    }
  }
}