.edit-ls {
  background-color: #ffc107;

  &:hover {
    background-color: #ffc913 !important;
  }
}

.ant-select-tree-title {
  font-weight: 500;
}