.container-online-library {
  .sector-online-library {
    .main-document-icon-online-library {
      background: url("../../../assets/document-library.svg") center center no-repeat;
      background-size: contain;
      width: 40px;
      height: 35px;
    }

    .filename-online-library {
      font-size: 14px;
      text-transform: uppercase;
      color: #004fff;
      font-weight: 800;
    }
  }
}