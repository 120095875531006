.c-dragger {
  .ant-upload {
    background: transparent;
    height: 134px;

    &-drag {
      border-radius: 5px;
      border: 1px dashed #c7c7c7;
    }

    &-hint {
      font-size: 18px;
      color: #828282;
    }

    &-btn {
      padding: 40px 0 !important;
      margin-top: 0;
    }
  }
}
