
.files-quotation {
  &__item {
    /*
    color: #7449EF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;*/
    border: 1px #ddd solid;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 4px 0 8px;
    height: auto;
    gap: 0 8px;

    &-btn {
      padding: 0;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 25px !important;
      scale: .8;
      border: none;
      box-shadow: none;
    }
  }
  &__icon {/*
    width: 46px;
    height: 46px;
    background: rgba(116, 73, 239, 0.3);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;*/
  }
  &__filename {
    margin: 0;
  }
}
