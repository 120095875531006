.quotation-objects {
  .quotation-object {
    background: #f8f8f8;
    height: 100%;
    display: flex;
    border-color: transparent;
    border-radius: 15px;

    &__breadcrumb {
      font-size: 16px;
      font-weight: normal;
    }

    &__title {
      text-transform: uppercase;
      margin: 5px 0 0;
    }

    &__description {
      font-size: 18px;
    }

    .ant-card-body {
      width: 100%;
      padding: 20px;
    }

    &_add-new {
      border-color: #c7c7c7;
      background: transparent;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;
      text-align: center;
    }
  }
}


