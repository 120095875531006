.logo {
  font-size: 32px;
  line-height: 1;
  font-weight: bold;

  &-img {
    max-width: 160px;
    max-height: 40px;
  }
}
