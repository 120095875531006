#proxy-renderer {
  overflow: hidden;
}

#header-bar {
  display: none;
}

.doc-viewer {
  height: 100vh;
}

.pdf-viewer {
  border: none;
  overflow: hidden;
  height: 100vh;
}

.iframe {
  border: none;
}

