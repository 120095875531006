.add-modal-useful {
  .ant-modal-content {
    .delete-btn-useful {
      border-radius: 16px;
      background-color: #d00000;
      color: #ffffff;
    }

    .reject-btn-useful {
      font-weight: 600;
      border-radius: 16px;
      background-color: #dae0ee;
      color: #e4572e;
    }
  }
}