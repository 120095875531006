.main-quotation {
  h1 {
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 38px;
  }

  h3.main-quotation__section-title {
    color: #7449ef;
  }

  .button-flow {
    position: relative;
    background: #dee3e9;
    width: 60px;
    height: 60px;
    border: none;
    font-size: 25px;
    color: #828282;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: color .3s ease;
    &.print-button{
      width: 100%;
      font-size: 14px;
      &:hover {
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.ant-dropdown-open{
          color: white;
          background-color: #1677ff;
        }
    }
    &:hover {
      color: #828282;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover .count-notification {
      width: 60px;
      height: 60px;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover .count-number {
      position: absolute;
      top: 6%;
      right: 10%;
    }

    &:hover .hover-bell {
      display: block;
    }

    &:hover .bell-icon {
      display: none;
    }
  }

  .subtitle-quotation {
    font-weight: 600;
    display: block;
  }

  .count-notification {
    width: 15px;
    height: 15px;
    font-size: 10px;
    position: absolute;
    top: 4%;
    right: 4%;
    background: #FF6262;
    border-radius: 5px;
    color: white;
    transition: width .3s ease, height .3s ease;
  }

  .hover-bell {
    width: 24px;
    height: 24px;
    display: none;
  }
}

.bold-label label{
  font-weight: 500;
}