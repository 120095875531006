.page-header {
  &.ant-layout-header {
    background: none;
    line-height: 1;
    padding: 15px 0 0;
    margin-bottom: 40px;
    height: auto;
  }

  &__inner {
    border-bottom: 1px solid #777;
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    justify-content: space-between;
    @media (max-width: 1199px) {
      justify-content: space-between;
    }
  }

  &__menu-button {
    flex: 0 0 auto;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__logo-wrap {
    @media (min-width: 1200px) {
      display: none;
    }
  }

  // background-color: #fff;
  // padding: 20px 0;
  // border-bottom: 1px solid #dedede;
  // position: fixed;
  // left: 0;
  // right: 0;
  // z-index: 9999;
  // top: 0;

  // &__inner {
  //   width: 100%;
  //   max-width: 1600px;
  //   margin: 0 auto;
  //   display: flex;
  //   align-items: center;
  //   padding: 0 15px;
  // }

  &__nav {
    list-style: none;
    padding: 0;
    margin: 0 auto 0 0;
    display: flex;
    gap: 30px;
    font-size: 16px;
    flex-wrap: wrap;

    &-item {
      a {
        color: #000;
        &:hover {
          color: #0057ff;
        }

        &.active {
          color: #0057ff;
        }
      }
    }

    @media (max-width: 1199px) {
      display: none;
    }
  }
}
