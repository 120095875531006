.page-sider {
  &.ant-layout-sider {
    background: #fff !important;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 15px 0 0 15px;

    @media (min-width: 1600px) {
      padding-left: 80px;
    }
  }

  &.ant-layout-sider-collapsed {
    padding: 0;
  }

  &__logo-wrap {
    padding-left: 26px;
    margin-bottom: 55px;
  }

  &__logo {
    font-size: 32px;
    color: #0057ff;
    font-weight: bold;
  }

  &__menu {
    font-size: 16px;
    border-inline-end: 0;
  }
}
