.layout-container {
  padding: 0 15px 60px 15px;

  @media (min-width: 1200px) {
    padding-left: 30px;
    margin-left: 320px;
  }

  @media (min-width: 1600px) {
    padding-left: 50px;
    padding-right: 100px;
  }
}
