.sector-useful {
  .highlighted-text-found {
    color : #e4572e;
  }

  .icon-download-found {
    display: inline-block;
    background: url("../../../assets/document-download-library.svg") center center no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    filter: brightness(0) saturate(100%) invert(46%) sepia(13%) saturate(5368%) hue-rotate(338deg) brightness(91%) contrast(96%);
  }

  .icon-document-found {
    display: inline-block;
    background: url("../../../assets/document-library.svg") center center no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    filter: brightness(0) saturate(100%) invert(46%) sepia(13%) saturate(5368%) hue-rotate(338deg) brightness(91%) contrast(96%);
  }

  .icon-get-hierarchy-found {
    display: inline-block;
    background: url("../../../assets/arrow-library.svg") center center no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px;
    filter: brightness(0) saturate(100%) invert(46%) sepia(13%) saturate(5368%) hue-rotate(338deg) brightness(91%) contrast(96%);
  }

  .button-found {
    background: none;
    border: none;
    box-shadow: none;
  }

  .title-item-found {
    width: 300px;
  }
}