.ant-typography.ant-typography-secondary {
  color: #0057ff;
}

/* Button success */
.ant-btn-success.ant-btn-text {
  color: #4cb351;
}

.ant-btn-success.ant-btn-text:hover,
.ant-btn-success.ant-btn-text:focus {
  color: #7ac77e;
}

.ant-btn-success.ant-btn-text:active {
  color: #2fa534;
}

/* progress */
.ant-progress-inner {
  background-color: #c4c4c4;
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: #7449ef;
}

.ant-form-item-extra {
  margin-top: 5px;
  line-height: 1.2;
}
