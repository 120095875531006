.events {
  &__row {
    background-color: #fff;
    padding: 35px 35px 50px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 25px;
  }

  &__create-event {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
}

.user_avatar{
  &__picture{
    margin-bottom: 15px;
    margin-top: 13px;
  }
  &__text {
    padding-top: 15px;
  }
  
}

.event-item-user {
  font-size: 20px;
  font-weight: normal;
  color: #474747;

  &__position{
    display: flex;
    justify-content: space-between;
  }

  &__author {
    display: flex;
    width:100%;
  }
  
  &__users-list {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 -25px;
    display: flex;
    flex-wrap: wrap;

    &-title {
      font-size: 13px;
      line-height: 1;
      margin-left: -35px;
      margin-top: -7px;
      padding: 8px 8px;
      word-spacing: 100vw;
      
      span {
        display: block;
      }
    }
}
}


.item-user{
  position: relative;
  &__img{
    width: 100%;
    height: 150px;
    position: absolute;
    left: -5px;
    // top: -5px;
  }
}

.avatar1234 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
