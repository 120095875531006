body{
    background-color: #fff !important;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0 !important;
}
.container{
    max-width: 1200px;
    margin: auto;
}
.main {
    background-color: #fff;
    border-bottom: 1px solid #d9d9d9;
    &__header {
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: #1677ff;
        padding: 20px 0;
    }
    .nav {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    &__logo {
        .icon {
            font-size: 32px;
            line-height: 1;
            font-weight: bold;
            text-decoration: none;
            color: #fff;
        }
    }
    &__menu {
        display: flex;
        gap: 25px;
    }
    &__item {
        cursor: pointer;
        color: #fff;
        font-size: 18px;
        &:hover {
            opacity: 65%;
        }
    }
    &__category {
        display: flex;
        justify-content: space-between;
    }
    &__org-struct{
        padding-top: 20px;
    }
    &__list {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 20px 20px;
        border-right: 1px solid #d9d9d9;
        .list-button {
            margin-top: 20px;
            height: auto;
            line-height: 23px;
            font-weight: 500;
            padding: 10px 10px;
            border-radius: 0;
        }
    }
    &__content {
        flex: 3;
        padding: 20px 20px;
        .document-item{
            display: flex;
            gap: 10px;
            align-items: start;
            transition: 300ms;
            margin-top: 20px;
            cursor: pointer;
            &__link {
                color: #000;
                text-decoration: none;
                &:hover {
                    color: #1677ff;
                }
            }
        }
    }

}

