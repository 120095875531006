.modal-notifications {
  width: 400px;
  position: absolute;
  top: 90%;
  right: 20%;
  background: white;
  z-index: 1000;
  border: #d5d5d5 2px solid;
  border-radius: 10px;
  animation: fadeIn .3s ease-out;
  min-height: 500px;

  .title {
    font-size: 20px;
    font-weight: 600;
    border-bottom: #d5d5d5 2px solid;
    padding: 20px;
    text-transform: capitalize;
  }

  .content {
    font-size: 20px;
    padding: 0 10px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.items {
  max-height: 500px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dee3e9;
    border-radius: 10px;
  }
}

.item {
  padding: 15px;
  border-radius: 5px;
  background: #f0f2f5;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background: #dee4ec;
  }

  .message {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: bold;
  }

  .comments {
    color: #8c8c8c;
    font-size: 15px;
  }

  .error {
    color: #ff6262;
  }

  .success {
    color: #38c33e
  }

  .neutral {
    color: #828282;
  }

  .datetime {
    width: 60%;
    color: #444;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
