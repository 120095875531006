.modal-btn {
    display: grid;
    grid-template-columns: 1fr 340px;
    gap: 15px;
    @media (max-width: 575px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0;
    }
  }

  .modal-btn__column {
    grid-column-start: 2; /* Сначала установите grid-column-start: 2; */
    @media (max-width: 575px) {
        grid-column-start: 2; /* Затем измените grid-column-start в медиа-запросе */
    }
  }

.datepicker {
  width: 100%;
}