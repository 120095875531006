.sector-online-library {
  .info-title-online-library {
    line-height: 0.5;
    font-weight: 700;
  }

  .title-name-information {
    width: 90%;
    margin-left: 10px;
    font-weight: 700;
  }

  .info-subtitle-online-library {
    font-weight: 400;
    font-size: 16px;
  }

  .close-btn-information {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    color: #004fff;
    border: 1px solid #dae0ee;
    border-radius: 16px;
    box-shadow: none;

    .close-icon-information {
      display: inline-block;
      background: url("../../../assets/delete-folder.svg") center center no-repeat;
      width: 15px;
      height: 15px;
      filter: brightness(0) saturate(100%) invert(18%) sepia(53%) saturate(6966%) hue-rotate(223deg) brightness(100%) contrast(107%);
    }
  }

  .icon-folder-information {
    display: inline-block;
    background: url("../../../assets/folder-library.svg") center center no-repeat;
    background-size: contain;
    width: 35px;
    height: 30px;
    filter: brightness(0) saturate(100%) invert(18%) sepia(53%) saturate(6966%) hue-rotate(223deg) brightness(100%) contrast(107%);
  }

  .icon-document-information {
    display: inline-block;
    background: url("../../../assets/document-library.svg") center center no-repeat;
    background-size: contain;
    width: 35px;
    height: 30px;
    filter: brightness(0) saturate(100%) invert(18%) sepia(53%) saturate(6966%) hue-rotate(223deg) brightness(100%) contrast(107%);
  }
}