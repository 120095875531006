.centcoin-card {
    margin-top: 20px;
    display: flex;
    .card-img {
        width: 100%;
        padding: 20px 20px;
        background-color: #Ffffff;
        .img {
            border-radius: 10px;
            box-shadow: 0px 20px 10px -9px  rgba(152, 152, 152, 0.58);
        }
        .insurance-logo {
            position: absolute;
            margin-left: 320px;
            margin-top: 25px;
        }
        .sim-card {
            position: absolute;
            margin-top: 150px;
            margin-left: 45px;
        }
        .flex-div {
            position: absolute;
            width: 6%;
            text-align: left;
            margin: 30px 0 0 45px;
            display: flex;
            .centcoin-count {
                position: relative;
                font-size: 70px;
                color: #Ffffff;
                font-weight: bold;
            }
            .cent {
                position: relative;
                float: right;
            }
        }
    }
    .centcoin-history{
        margin-left: 10px;
        // width: 50%;
        padding: 30px 30px;
        background-color: #Ffffff;
        .show-btn {
            width: 80%;
            height: 36%;
            border-radius: 5px;
            border: 1px solid #7449ef;
            color: #7449ef;
            // padding: 5px;
            .optic-img {
                width: 9%;
                margin-right: 12px;
            }
        }
    }
}
.history-table {
    margin-top: 1em;
    padding: 1em;
    background-color: #Ffffff;
    display: flex;
    flex-direction: column;
    .operation-history-table {
        overflow: hidden;
        border-radius: 6px;

        tbody tr {
            &:nth-child(even) {
                background: #d9e3ff;
            }
        }
        thead {
            height: 59px;
            background: #7449ef;
            color: white;
            padding: 25px !important;
        }
    
        td,
        th {
            padding-left: 10px;
            max-width: 100%;
        }

        th:last-child {
            padding-right: 10px;
          }
          
        tr {
        height: 59px;
        max-width: 100%;
        }
          
    }
    .centcoin-spend-button {
        height: 50px;
        width: 30%;
        background: #7449EF;
        font-size: 24px;
        color: #fff;
        border-radius: 5px;
        margin-top: 20px;
        margin-left: auto;
    }
}

.apply-centcoin {
    margin-top: 1em;
    padding-top: 90px;
    background-color: #Ffffff;
    text-align: left;
    padding-left: 25px;
    .select-button {
        display: flex;
        .select-present {
            width: 50%;
            height: 55px;
            font-size: 18px;
            margin-bottom: 20em;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            width: 100%;
            height: 50px;
            background: #F0F2F5 !important;
            padding: 0 11px;
            border-radius: 5px !important;
        }
        .ant-select-selector{
            align-items: center;
        }

        .apply-button{
            margin-left: 75px;
            width: 20%;
            height: 55px;
            border-radius: 5px;
            background-color: #Ffffff;
            border: 1px solid #7449ef;
            color: #7449ef;
            padding: 15px;
        }
    }
}

.apply-button{
    width: 80%;
    height: 36%;
    border-radius: 5px;
    border: 1px solid #7449ef;
    color: #7449ef;
    padding: 5px;
    .optic-img {
        width: 9%;
        margin-right: 12px;
    }
}

.select-present {
    width: 91%;
    // height: 55px;
    font-size: 18px;
    // margin-bottom: 20em;
}

.p-0
{
    padding: 0;
}
