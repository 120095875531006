.event-item-heading {
  position: relative;
  width: 100%;

  &__title {
    font-size: 14px;
    color: #A4A4A4;
    font-weight: 300;
    margin: 0;
    padding-right: 120px;
  }

  &__company {
    padding-right: 120px;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
  
  &-color {
    color: #7449EF;
  }
  }


  &__button {
    svg {
      font-size: 24px;
    }
  }
  
  &__group-button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  /* Rewrite ant */
  .ant-collapse {
    background-color: transparent;
    border: 0;
  }

  .ant-collapse-item-active,
  .ant-collapse-item-active .ant-collapse-content {
    background-color: #f1f1f1;
  }
}