.ant-upload-btn {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 3px;
    display: flex;
    gap: 5px;
}
.ant-upload-icon {
    border: none;
}
