.container-file-exchange {
  .sector-file-exchange {
    .main-document-icon-file-exchange {
      background: url("../../../assets/document-library.svg") center center no-repeat;
      background-size: contain;
      width: 40px;
      height: 35px;
    }

    .percent-file-exchange {
      max-width: 50px;
      font-weight: 600;
      color: #004fff;
      font-size: 18px;
      overflow: hidden;
      text-overflow: clip;
    }

    .item-file-exchange {
      min-width: 180px;
      border: 1px solid #dae0ee;
      border-radius: 20px;
      padding: 20px;
    }
  }
}