.c-tabs {
    .ant-tabs-nav {
        margin: 0;

        div {
            transition: none !important;
        }

        .ant-tabs-tab {
            margin-right: 16px;
            border-radius: 5px 5px 0 0 !important;
            padding: 7px;
            background-color: #fff;

            .ant-tabs-tab-btn {
                border: 1px solid transparent;
                padding: 7px 65px;
                font-size: 18px;
            }

            span {
                color: #626d7a;
            }

            &-active {
                border-color: transparent;
                font-weight: 600;

                span {
                    color: #7449ef;
                }
            }

            &:not(.ant-tabs-tab-active) {
                background: transparent;
                border-color: transparent;

                .ant-tabs-tab-btn {
                    border-color: #c7c7c7;
                    background: #fff;
                    border-radius: 5px;
                    width: 100%;
                    display: block;
                }
            }
        }

        &::before {
            border: none;
        }
    }

    &.ant-input {
        background: #f0f2f5;
        color: #828282;

        &-lg {
            height: 45px;
            border-radius: 5px;
        }
    }

    .ant-tabs-content {
        .ant-card {
            border: none;
            border-radius: 0 0 5px 5px;
        }
    }
}
