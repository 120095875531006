.container-useful {
  .sector-useful {
    .add-category-useful {
      width: 100%;
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #dae0ee;
      color: #e4572e;
      border-radius: 16px;

      .button-text-useful {
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #e4572e;
        font-weight: 600;
      }
    }

    .menu-categories-useful {
      border-inline-end: #ffffff;
    }

    .ant-menu-item {
      font-size: 15px;
      display: flex;
      align-items: center;
      border-radius: 16px;
      padding: 10px;
      font-weight: 600;
      height: auto;

      .close-button-useful,
      .edit-button-useful {
        background: none;
        border: none;
        box-shadow: none;
      }

      .close-icon-useful,
      .edit-icon-useful {
        display: inline-block;
        background-size: contain;
        width: 15px;
        height: 15px;
      }

      .close-icon-useful {
        background: url("../../../assets/delete-folder.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(44%) sepia(95%) saturate(2851%) hue-rotate(346deg) brightness(95%) contrast(87%);
      }

      .edit-icon-useful {
        background: url("../../../assets/edit-library.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(44%) sepia(22%) saturate(3351%) hue-rotate(340deg) brightness(92%) contrast(93%);
      }

      .folder-icon-useful {
        background: url("../../../assets/folder-library.svg") center center no-repeat;
        background-size: contain;
        width: 30px;
        height: 28px;
        filter: brightness(0) saturate(100%) invert(44%) sepia(22%) saturate(3351%) hue-rotate(340deg) brightness(92%) contrast(93%);
      }

      .ant-menu-title-content {
        color: #e4572e;
        width: 200px;
        white-space: pre-wrap;
        line-height: normal;
        font-size: 14px;
      }

      .edit-icon-useful {
        background: url("../../../assets/edit-library.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(44%) sepia(22%) saturate(3351%) hue-rotate(340deg) brightness(92%) contrast(93%);
      }

      .close-icon-useful {
        background: url("../../../assets/delete-folder.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(44%) sepia(22%) saturate(3351%) hue-rotate(340deg) brightness(92%) contrast(93%);
      }
    }

    .ant-menu-item-selected {
      color: #ffffff;
      background-color: #e4572e;

      .folder-icon-useful {
        background: url("../../../assets/folder-library.svg") center center no-repeat;
        background-size: contain;
        width: 30px;
        height: 28px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(6%) hue-rotate(252deg) brightness(107%) contrast(100%);
      }

      .edit-icon-useful {
        background: url("../../../assets/edit-library.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(306deg) brightness(104%) contrast(101%);
      }

      .close-icon-useful {
        background: url("../../../assets/delete-folder.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(306deg) brightness(104%) contrast(101%);
      }

      .ant-menu-title-content {
        color: #ffffff;
        width: 200px;
        white-space: pre-wrap;
        line-height: normal;
        font-size: 14px;
      }
    }
  }
}



