.sign-modal {
  .ant-modal-content {
    min-width: 520px !important;
    border-radius: 16px;
  }

  &__label {
    font-weight: 600;
    color: #a0aac2;
    font-size: 14px;
  }

  &__title {
    font-weight: 700;
    color: #1c1c20;
    font-size: 14px;
  }
}