.user-info {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
    padding: 17px 40px 62px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    &__avatar {
        background-color: #fff;
        box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        flex: 0 0 100%;
        max-width: 188px;
        margin-right: 65px;
        position: relative;

        @media (max-width: 1400px) {
            margin-right: 35px;
        }

        &:hover {
            .user-info__avatar-button-delete,
            .user-info__avatar-button-upload {
                display: flex;
            }
        }

        &-image {
            border-radius: 5px;
            width: 188px;
            height: 188px;
            object-fit: cover;
        }

        &-button-delete {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            border: 0;
            background-color: rgba(0, 0, 0, 0.5);
            width: 22px;
            height: 22px;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
        }

        &-button-upload {
            display: none;
            position: absolute;
            cursor: pointer;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0;
            font-size: 60px;
            background-color: transparent;
            color: #fff;
            width: 70px;
            height: 70px;
            align-items: center;
            justify-content: center;
        }
    }

    &__about {

    }

    &__heading {
        margin-bottom: 25px;
    }

    &__name {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 8px;
        color: #2D2D2D;
        font-weight: bold;
    }

    &__duty {
        font-size: 18px;
        line-height: 1.2;
        color: #959595;
        margin-bottom: 0;
    }

    &__additional-info {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #1C1C1C;

        &-item {
            margin-bottom: 15px;
            display: flex;

            @media (max-width: 1400px) {
                flex-direction: column;
            }
        }

        &-title {
            flex: 1 0 100%;
            width: 100%;
            color: #C4C4C4;
            max-width: 125px;
        }

        &-text {
            padding-left: 25px;

            @media (max-width: 1400px) {
                padding-left: 0;
            }
        }
    }

    &__raiting {
        position: absolute;
        font-weight: 700;
        right: 17px;
        width: 53px;
        height: 53px;
        color: #fff;
        border-radius: 8px;
        background-color:  #1677FF;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
    }
}