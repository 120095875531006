.preloader {
    z-index: 1001;
    display: none;
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: hidden;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    background: rgba(0, 220, 255, .3);
    opacity: .8
}

.item-1, .preloader {
    justify-content: center;
    align-items: center
}

.item-1 {
    width: 20px;
    height: 20px;
    background: #f583a1;
    border-radius: 50%;
    background-color: #e93126;
    margin: 7px;
    display: flex
}

@-webkit-keyframes scale {
    0% {
        transform: scale(1)
    }
    50%, 75% {
        transform: scale(2.5)
    }
    78%, to {
        opacity: 0
    }
}

.item-1:before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e93126;
    opacity: .7;
    -webkit-animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
    transition: all .5s ease;
    transform: scale(1)
}

.item-2 {
    background: #f583a1;
    background-color: #b63545;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center
}

.item-2, .item-2:before {
    width: 20px;
    height: 20px;
    border-radius: 50%
}

.item-2:before {
    content: "";
    background-color: #b63545;
    opacity: .7;
    -webkit-animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    transition: all .5s ease;
    transform: scale(1)
}

.item-3 {
    background: #f583a1;
    background-color: #81365c;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center
}

.item-3, .item-3:before {
    width: 20px;
    height: 20px;
    border-radius: 50%
}

.item-3:before {
    content: "";
    background-color: #81365c;
    opacity: .7;
    -webkit-animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
    transition: all .5s ease;
    transform: scale(1)
}

.item-4 {
    background: #f583a1;
    background-color: #573a76;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center
}

.item-4, .item-4:before {
    width: 20px;
    height: 20px;
    border-radius: 50%
}

.item-4:before {
    content: "";
    background-color: #573a76;
    opacity: .7;
    -webkit-animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
    transition: all .5s ease;
    transform: scale(1)
}

.item-5 {
    width: 20px;
    height: 20px;
    background: #f583a1;
    border-radius: 50%;
    background-color: #363d8a;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center
}

@keyframes scale {
    0% {
        transform: scale(1)
    }
    50%, 75% {
        transform: scale(2.5)
    }
    78%, to {
        opacity: 0
    }
}

.item-5:before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #363d8a;
    opacity: .7;
    -webkit-animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    animation: scale 2s cubic-bezier(0, 0, .49, 1.02) infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    transition: all .5s ease;
    transform: scale(1)
}

.lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 !important;
    padding: 0;
    pointer-events: none;
    width: 100%
}

.lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s
}

.lightrope li:nth-child(odd) {
    background: #0ff;
    box-shadow: 0 4.6666666667px 24px 3px rgba(0, 255, 255, .5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: .4s;
    animation-duration: .4s
}

.lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s
}

.lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s
}

.lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s
}

.lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px
}

.lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: 2px solid #222;
    border-radius: 50%
}

.lightrope li:last-child:after {
    content: none
}

.lightrope li:first-child {
    margin-left: -40px
}

@-webkit-keyframes flash-1 {
    0%, to {
        background: #00f7a5;
        box-shadow: 0 4.6666666667px 24px 3px #00f7a5
    }
    50% {
        background: rgba(0, 247, 165, .4);
        box-shadow: 0 4.6666666667px 24px 3px rgba(0, 247, 165, .2)
    }
}

@keyframes flash-1 {
    0%, to {
        background: #00f7a5;
        box-shadow: 0 4.6666666667px 24px 3px #00f7a5
    }
    50% {
        background: rgba(0, 247, 165, .4);
        box-shadow: 0 4.6666666667px 24px 3px rgba(0, 247, 165, .2)
    }
}

@-webkit-keyframes flash-2 {
    0%, to {
        background: #0ff;
        box-shadow: 0 4.6666666667px 24px 3px #0ff
    }
    50% {
        background: rgba(0, 255, 255, .4);
        box-shadow: 0 4.6666666667px 24px 3px rgba(0, 255, 255, .2)
    }
}

@keyframes flash-2 {
    0%, to {
        background: #0ff;
        box-shadow: 0 4.6666666667px 24px 3px #0ff
    }
    50% {
        background: rgba(0, 255, 255, .4);
        box-shadow: 0 4.6666666667px 24px 3px rgba(0, 255, 255, .2)
    }
}

@-webkit-keyframes flash-3 {
    0%, to {
        background: #f70094;
        box-shadow: 0 4.6666666667px 24px 3px #f70094
    }
    50% {
        background: rgba(247, 0, 148, .4);
        box-shadow: 0 4.6666666667px 24px 3px rgba(247, 0, 148, .2)
    }
}

@keyframes flash-3 {
    0%, to {
        background: #f70094;
        box-shadow: 0 4.6666666667px 24px 3px #f70094
    }
    50% {
        background: rgba(247, 0, 148, .4);
        box-shadow: 0 4.6666666667px 24px 3px rgba(247, 0, 148, .2)
    }
}

.tooltip {
    display: block !important;
    z-index: 10000
}

.tooltip .tooltip-inner {
    background: #4267b2;
    color: #fff;
    border-radius: 16px;
    padding: 5px 10px 4px
}

.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #4267b2
}

.tooltip[x-placement^=top] {
    margin-bottom: 5px
}

.tooltip[x-placement^=top] .tooltip-arrow {
    border-width: 5px 5px 0;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0
}

.tooltip[x-placement^=bottom] {
    margin-top: 5px
}

.tooltip[x-placement^=bottom] .tooltip-arrow {
    border-width: 0 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0
}

.tooltip[x-placement^=right] {
    margin-left: 5px
}

.tooltip[x-placement^=right] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0
}

.tooltip[x-placement^=left] {
    margin-right: 5px
}

.tooltip[x-placement^=left] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0
}

.tooltip[aria-hidden=true] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s
}

.tooltip[aria-hidden=false] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s
}

.products-link:hover {
    background: #4974cc
}

#products-nav {
    width: 100%;
    font-weight: 700
}

#products-nav li {
    margin-right: 0;
    position: relative;
    display: block
}

#products-nav li a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-weight: 400
}

#products-nav ul li {
    padding-top: 5px;
    float: none
}

#full a:hover, #products-nav li:hover ul #express a:hover {
    background: #4974cc
}

.sub-menu {
    display: none;
    position: absolute;
    z-index: 99999;
    background: #4267b2
}

.products-item {
    outline: none
}

.products-item:focus ~ .sub-menu, .sub-menu:hover {
    display: block
}

#products-nav li a.item {
    width: 185px
}

.childs-not-bold h4, .childs-not-bold label {
    font-weight: 300
}

.childs-not-bold h4.bold, .childs-not-bold label.bold {
    font-weight: 600
}

.day-indicator.today {
    background-color: #b2f4f4
}

.countdown-main {
    background-color: #4267b2;
    color: #fff
}

.bold-text {
    font-weight: 700
}

.fas {
    margin-right: 1px
}
.searchClient-radio label{
    width: 50%;
}