.c-select {
  &.ant-select {
    &.ant-select {
      &-lg {
        .ant-select-selector {
          height: 45px;
          border-radius: 5px;

          .ant-select-selection-placeholder {
            line-height: 43px;
          }

          &::after {
            visibility: visible;
            border-left: 1px solid #d9d9d9;
            width: 35px;
          }
        }

        .ant-select-selection-item {
          line-height: 43px;
        }

        .ant-select-arrow {
          .anticon {
            width: 15px;
            margin-right: 5px;
          }
          svg {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }
}
