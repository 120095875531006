.content-online-library {
  background-color: #F1F5FE;
  min-height: 100vh;

  .container-online-library {
    margin: 0 auto;
    padding: 10px;
    width: 95%;

    .path-category-online-library {
      width: 50%;
    }

    .document-btn-online-library,
    .add-folder-online-library {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #004fff;
      border-radius: 16px;
      color: #ffffff;
      padding: 20px 20px;
      font-weight: 600;
    }

    .back-btn-online-library {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #dae0ee;
      border-radius: 16px;
      color: #004fff;
      padding: 20px 20px;
      font-weight: 600;
    }

    .download-document-icon-online-library {
      display: inline-block;
      background: url("../../assets/document-download-library.svg") center center no-repeat;
      background-size: contain;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    .search-input-online-library {
      background: none;
      padding: 10px;
      border: 1px solid #cad0de;
      border-radius: 50px;
      color: #192749;
      font-size: 15px;

      .search-icon-online-library {
        color: #cad0de;
        font-size: 20px;
      }

      .ant-input {
        background: none;
      }
    }

    .header-online-library {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sector-online-library {
      width: 100%;
      border: 1px solid #dae0ee;
      border-radius: 40px;
      padding: 30px 20px;
      background-color: #ffffff;
      margin-top: 20px;
    }
  }
}


