.container-online-library {
  .sector-online-library {
    .add-category-online-library {
      width: 100%;
      padding: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #dae0ee;
      color: #004fff;
      border-radius: 16px;

      .button-text-online-library {
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #004fff;
        font-weight: 600;
      }
    }

    .menu-categories-online-library {
      border-inline-end: #ffffff;
    }

    .ant-menu-item {
      font-size: 15px;
      color: #cad0de;
      display: flex;
      align-items: center;
      border-radius: 16px;
      padding: 10px;
      font-weight: 600;
      height: auto;

      .close-button-online-library,
      .edit-button-online-library {
        background: none;
        border: none;
        box-shadow: none;
      }

      .close-icon-online-library,
      .edit-icon-online-library {
        display: inline-block;
        background-size: contain;
        width: 15px;
        height: 15px;
      }

      .close-icon-online-library {
        background: url("../../../assets/delete-folder.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(92%) sepia(9%) saturate(280%) hue-rotate(185deg) brightness(89%) contrast(96%);
      }

      .edit-icon-online-library {
        background: url("../../../assets/edit-library.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(92%) sepia(9%) saturate(280%) hue-rotate(185deg) brightness(89%) contrast(96%);
      }

      .folder-icon-online-library {
        background: url("../../../assets/folder-library.svg") center center no-repeat;
        background-size: contain;
        width: 30px;
        height: 28px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(15%) saturate(7013%) hue-rotate(178deg) brightness(97%) contrast(79%);
      }

      .ant-menu-title-content {
        color: #004fff;
        width: 200px;
        white-space: pre-wrap;
        line-height: normal;
        font-size: 14px;
      }

      .folder-icon-online-library {
        background: url("../../../assets/folder-library.svg") center center no-repeat;
        background-size: contain;
        width: 30px;
        height: 28px;
        filter: brightness(0) saturate(100%) invert(21%) sepia(92%) saturate(4445%) hue-rotate(221deg) brightness(97%) contrast(113%);
      }

      .edit-icon-online-library {
        background: url("../../../assets/edit-library.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(21%) sepia(92%) saturate(4445%) hue-rotate(221deg) brightness(97%) contrast(113%);
      }

      .close-icon-online-library {
        background: url("../../../assets/delete-folder.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(21%) sepia(92%) saturate(4445%) hue-rotate(221deg) brightness(97%) contrast(113%);
      }
    }

    .ant-menu-item-selected {
      color: #ffffff;
      background-color: #004fff;

      .folder-icon-online-library {
        background: url("../../../assets/folder-library.svg") center center no-repeat;
        background-size: contain;
        width: 30px;
        height: 28px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(6%) hue-rotate(252deg) brightness(107%) contrast(100%);
      }

      .edit-icon-online-library {
        background: url("../../../assets/edit-library.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(306deg) brightness(104%) contrast(101%);
      }

      .close-icon-online-library {
        background: url("../../../assets/delete-folder.svg") center center no-repeat;
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(306deg) brightness(104%) contrast(101%);
      }

      .ant-menu-title-content {
        color: #ffffff;
        width: 200px;
        white-space: pre-wrap;
        line-height: normal;
        font-size: 14px;
      }
    }
  }
}



