.c-segmented {
  background: #e6eaef;
  color: #2d2d2d;
  font-weight: 700;

  &.ant-segmented {
    &-lg {
      padding: 5px;
      border-radius: 10px;

      .ant-segmented-item-label {
        font-size: 24px;
      }

      .ant-segmented-item {
        height: 50px;
      }
    }
  }

  .ant-segmented-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &-selected {
      background: #fff;
    }
  }
}
