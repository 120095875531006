.quotation-members {
  .ant-card-body {
   padding: 15px 14px;
  }

  &__button {
    height: 45px;
    width: 45px;
  }
}
