.calendar-salesman {
  gap: 20px;
  width: 100%;
  &__brutto-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 30px;
    gap: 10px;
  }
  &__brutto-item {
    display: grid;
    gap: 30px;
    text-align: center;
    background-color: rgb(245, 244, 244);
    align-items: center;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.24) -1px 2px 15px 0px;
    &:nth-child(1) {
      display: grid;
      grid-row-start: 1 !important;
      grid-row-end: 3 !important;
    }
    &:nth-child(6) {
      display: grid;
      grid-column-start: 4;
      grid-row-start: 1 !important;
      grid-row-end: 3 !important;
    }
  }

  &__button {
    text-transform: capitalize;
  }

  &__title {
    color: black !important;
    font-weight: 700 !important;
    font-size: 16px;
  }

  .ant-space {
    flex-wrap: wrap !important;
  }

  .ant-picker-cell-inner.ant-picker-cell-in-view {
    width: 90%;
    height: 90%;
    margin: auto;
    color: #CAD0DE;
    padding: 5px 0;
    transition: 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    font-size: 18px;
  }

  .ant-picker-calendar .ant-picker-panel .ant-picker-content  {
    height: 310px;
  }

  &__progress-title {
    color: black !important;
    font-weight: 700 !important;
    font-size: 22px !important;
    line-height: 10px !important;
    margin: 0 !important;
  }

  &__progress-subtitle {
    color: grey !important;
    font-weight: 700 !important;
    font-size: 12px;
  }

  &__progress-brutto-subtitle {
    color: grey !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    font-size: 14px !important;
  }

  &__progress-sum {
    color: black !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 0 !important;
  }

  &__vertical-divider {
    width: 2px;
    height: 100%;
    background: black;
  }

  &__horizontal-divider {
    width: 100%;
    height: 2px;
    background: black;
  }

  &__paid-icon {
    display: block;
    background: url('../../../../assets/note-text.svg') center center no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
  }

  &__plan-icon {
    display: block;
    background: url('../../../../assets/archive-tick.svg') center center no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
  }
}

.ant-picker-calendar .ant-picker-cell-disabled::before {
  border-radius: 20px;
  height: 40px;
  margin-right: 10px;
}

.raiting-sales {
  display: block;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) -1px 2px 15px 0px;
  margin-bottom: 25px;
  width: 100%;
  height: 700px;
}

.raiting-h {
  height: 200px;
}

.question-icon {
  font-size: 20px;
  margin-left: 5px;
}