@import 'react-big-calendar/lib/sass/styles';

.events-invite-list {
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    border-bottom: 1px solid #A4A4A4;
    border-radius: 0;
  }

  .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    background-color: #F8F5FF;
    // padding-left: 0;
    // padding-right: 0;
  }
}
