.sector-online-library {
  .highlighted-text-found {
    color : #004fff;
  }

  .icon-download-found {
    display: inline-block;
    background: url("../../../assets/document-download-library.svg") center center no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    filter: brightness(0) saturate(100%) invert(14%) sepia(10%) saturate(3749%) hue-rotate(185deg) brightness(94%) contrast(96%);
  }

  .icon-document-found {
    display: inline-block;
    background: url("../../../assets/document-library.svg") center center no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    filter: brightness(0) saturate(100%) invert(22%) sepia(83%) saturate(6649%) hue-rotate(224deg) brightness(103%) contrast(107%);
  }

  .icon-get-hierarchy-found {
    display: inline-block;
    background: url("../../../assets/arrow-library.svg") center center no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px;
  }

  .button-found {
    background: none;
    border: none;
    box-shadow: none;
  }

  .title-item-found {
    width: 300px;
  }
}