.add-modal-useful {
  .ant-modal-content {
    border-radius: 40px;

    .create-button-useful {
      border-radius: 16px;
      background: #e4572e;
    }
  }

  .ant-modal-close {
    padding: 20px;
    border: 1px solid #e4572e;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #e4572e;
    }
  }
}