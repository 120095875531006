.c-table {
  &.ant-table-wrapper {
    thead {
      tr {
        th {
          background: transparent;
          &::before {
            display: none;
          }
        }
      }
    }

    .ant-table {
      background: transparent;
    }

    tbody {
      tr {
        color: #2d2d2d;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        td {
          padding: 8px;
        }

        &:nth-child(even) {
          background: #fff;
        }

        &:nth-child(odd) {
          background: #f6f8fb;
        }
      }
    }
  }

  .quotqtion-id {
    cursor: pointer;
  }
}


