.c-radio {
    h5.ant-typography {
        font-size: 14px;
        margin-bottom: 0;
    }
    .ant-radio-group {
        display: flex;
        .ant-radio-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            color: black;
        }

        .ant-radio-button-wrapper-checked:nth-child(1) {
            background-color: #39c33e;
            border: 1px solid #39c33e;
        }

        .ant-radio-button-wrapper-checked:nth-child(2) {
            background-color: #ff6163;
            border: 1px solid #ff6163;
        }

        ::before {
            display: none;
        }

        label{
            width: 50%;

        }

        &-lg {
            .ant-radio-button-wrapper {
                font-size: 18px;
                height: 45px;
            }
        }
    }
}
