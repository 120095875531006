.c-treeselect {
  &.ant-select {
    &.ant-select {
      &-lg {
        .ant-select-selector {
          height: 45px;
          border-radius: 5px;

          .ant-select-selection-placeholder {
            line-height: 43px;
          }
        }

        .ant-select-selection-item {
          line-height: 43px;
        }
      }
    }
  }
}
