.quotation-page {

    &__title{
        margin-top: 40px;
    }

  }

.options{
    width: 100%;
    height: 0px!important;
    border: 1px solid #C4C4C4!important;
}

.c-form {
    .ant-form-item {
        margin-bottom: 10px;

        &-row {
            display: flex;
        }

        &-explain {
            transition: none;
        }

        &-label {
            order: 1;
            padding: 6px 0 8px;
        }

        &-has-error {
            .ant-form-item-label {
                display: none;
                // margin-bottom: -24px;
                // transition: none;
            }

            /*
            .ant-form-item-control-input + div {
                display: block !important;
            }
            */


        }

        // Фикс бага с дерганием лейбла при ошибке
        &:not(.ant-form-item-has-error) {
            .ant-form-item-control-input + div {
                display: none !important;
            }
        }
    }
}
