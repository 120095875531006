.c-datepicker {
  &.ant-picker {
   
    &-large {
      padding: 8.5px 11px 8.5px;
      height: 45px;
      border-radius: 5px;
    }

    &-suffix {
      margin-right: 2px;
    }
    :has(.ant-picker-clear).ant-picker-input:hover .ant-picker-suffix{
      margin-right: 21px;
    }
    :has(.ant-picker-clear).ant-picker-input:hover .ant-picker-suffix span{
      display: none;
    }
    .ant-picker-suffix {
      align-items: center;

      &::before {
        content: '';
        display: block;
        border-left: 1px solid #d9d9d9;
        height: 43px;
        margin-right: 13px;
      }

      .anticon {
        &-calendar {
          background: url("/assets/img/icon-calendar.svg") center center no-repeat;
          width: 21px;
          height: 21px;

          svg {
            display: none;
          }
        }
      }
    }
  }
}
