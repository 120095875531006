$font-path: '../assets/fonts/';

@font-face {
	font-family: 'MuseoSans';
	src: url('#{$font-path}MuseoSansCyrl-300.woff2') format('woff2'),
			url('#{$font-path}MuseoSansCyrl-300.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MuseoSans';
	src: url('#{$font-path}MuseoSansCyrl-500.woff2') format('woff2'),
			url('#{$font-path}MuseoSansCyrl-500.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MuseoSans';
	src: url('#{$font-path}MuseoSansCyrl-700.woff2') format('woff2'),
			url('#{$font-path}MuseoSansCyrl-700.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}