.bday {
  padding: 44px 36px 100px 30px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
  background-color: #ffffff;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__item {
    padding-bottom: 25px;
  }

  &__content {
    flex: 1 1 auto;
    line-height: 1;
    background-color: #fff;
  }

  &__link {
    display: flex;

    &:hover {
      h5.ant-typography {
        color: #40a9ff;
        transition: color 0.3s;
      }
    }
  }

  /* Ant design override */

  .ant-carousel {
    .slick-prev,
    .slick-next {
      top: 0;
      z-index: 2;
      color: #000 !important;
      font-size: 20px;
      height: 30px;
      margin-top: 0;

      &:hover,
      &:active {
        color: #7449ef !important;
      }

      &.slick-disabled {
        opacity: 0.2;

        &:hover {
          color: #000 !important;
        }
      }
    }

    .slick-prev {
      left: -5px;
    }

    .slick-next {
      right: -5px;
    }
  }

  .ant-avatar {
    flex: 0 0 auto;
    margin-right: 10px;
  }

  .ant-typography {
    font-weight: normal;
    line-height: 1.2;
  }

  .slick-prev::before,
  .slick-next::before {
    display: none;
  }
}
