.c-form {
  .ant-form-item {
    &-row {
      display: flex;
    }

    &-label {
      order: 1;
      padding: 6px 0 8px;
    }

    &-has-error {
      .ant-form-item-label {
        display: none;
        transition: none;
      }
    }

    .ant-select {
      .ant-select-clear {
        inset-inline-end: 12px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
