.user-profile-data {
  background: white;
  //width: 980px;
  width: 100%;
  height: 118px;
  max-width: 100%;
  box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin-top: 17px;
  padding-left: 35px;
  padding-right: 15px;
}
.options-about{
  display: flex;
  justify-content: space-around;
}
.user-options{
  margin-top: 48px;
  padding-bottom: 14px;
  padding-left: 50px;
  padding-right: 50px;
}
.user-options:hover{
  border-bottom:1px solid #7449EF ;
}
.user-option-about{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #313131;
  cursor: pointer;
}
.user-option-about:hover {
  color: #7449EF;
}
.user-dossier::selection{
  color: #7449EF;
}
.options-divider{
  //width: 930px!important;
  width: 98%;
  height: 0px!important;
  border: 1px solid #C4C4C4!important;
  margin-top: -1px!important;
}
.main-info{
  margin: auto;
  max-width: 100%;
  width: 100%;
}
.main-info-about{
  display: block!important;
}
.main-info-headers{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #313131;
}
.content {
  margin-top: 5px;
  text-align: left;
}
.wrapper{
  display: flex;
  justify-content: center;
}
.collapsible{
  width: 100%;
  overflow: hidden;
}
.collapsible label{
  position: relative;
  font-weight: 600;
  background-color: #FFFFFF;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
}
.collapsible_text{
  overflow: hidden;
}
.btn {
  background: #7449EF !important;
  padding: 10px 10px 40px !important;
  font-size: 14px !important;
  color: #fff !important;
  border-radius: 5px !important;
  margin-top: 20px !important;
}
.p-0
{
  padding: 0;
}

.mt
{
  margin-top: 15px;
}

