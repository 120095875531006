.ispring-page {
  #root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  }

  .ant-spin-text {
    font-size: 18px;
  }
}
