body {
  background-color: #f6f6f9;
  font-family: 'MuseoSans';
  line-height: 1.2;
  font-size: 16px;
  font-weight: 300;
  color: #000;
}

img {
  max-width: 100%;
  height: auto;
}

.ant-layout,
.ant-layout-sider {
  background: #f6f6f9 !important;
}

.nav__item {
  color: #474747 !important;
  padding-left: 50px !important;
  width: 200px;
}

.nav__item:hover {
  content: '';
  color: #7449ef !important;
  border-bottom: 1px solid #7449ef;
  transition: all 0.1s ease;
}

.purp-icon:hover {
  color: #7449ef;
  transition: all 0.1s ease;
  cursor: pointer;
}

.purp-icon-move {
  margin-right: 10px;
}
.page {
  height: 100%;
  max-width: 100%;
}

.card {
  position: relative;
}

.card__digits {
  font-weight: bold;
  font-family: 'Museo Sans Cyrl';
  font-size: 70px;
  display: block;
  color: #ffffff;
}

.card__container {
  position: absolute;
  left: 90px;
  top: 90px;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subflex-card {
  width: 55%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/******************
Navigation



********************/
.nav-nav-navigation {
  display: none;
}

.unactive-brand {
  display: none !important;
}

.unactive-brand-logo {
  display: none !important;
}
.colleague-search {
  display: block;
  margin-top: 20px !important;
  max-width: 92.5%;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 750px) {
  .active-page {
    overflow-y: hidden;
  }

  .nav-nav-navigation {
    width: 100vh;
    height: 100vh;
    background: white;
    max-width: 100%;
    display: none;
  }

  .unactive-brand {
    display: block !important;
  }

  .unactive-brand-logo {
    display: inline !important;
  }

  .active {
    display: block;
    transition: all 0.3s ease-in-out;
  }

  .nav__list {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .nav__link {
    color: #474747;
    font-family: Museo Sans Cyrl;
    font-size: 20px;
    line-height: 23px;
  }

  .nav__item {
    margin-top: 30px;
  }

  .nav__heading {
    color: #0057ff;
    text-align: center;
  }

  .nav__brand {
    display: flex;
    align-items: center;
  }

  .nav__logo {
    font-size: 40px;
    margin-right: 20px;
    transition: all 0.3s ease-in-out;
  }

  // .sbday {
  //   display: none !important;
  // }
}

/********************
********************/

// .ant-layout-content {
//   padding: 0 25px 50px 50px;
// }

.back-top {
  height: 40px;
  width: 40px;
  font-size: 24px;
  color: #fff;
  border-radius: 3px;
  background-color: #815ced;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
}

.skeleton-post {
  background-color: #fff;
  padding: 25px 40px;
  margin-bottom: 40px;
  box-shadow: 0px 0px 16px rgb(0 0 0 / 6%);
  border-radius: 5px;
}

// disabled form element

.ant-picker-input > input[disabled],
.ant-input[disabled],
.ant-input-number-input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.7) !important;
  cursor: text !important;
}

.ant-collapse-header{
  align-items: center !important;
}

.download-file-jur .ant-upload-wrapper{
  display: block;
}
.download-file-jur .ant-upload-wrapper .ant-upload{
  width: 100%;
}
.upload-form .ant-popover{
  max-width: 45%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.text-align {
  text-align: center;
}
.desc-text{
  margin-top: 15px;
  p{
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.ant-input-number-handler-wrap{
  display: none !important;
}