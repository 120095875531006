.c-input {
  width: 100%;
  &.ant-input {
    color: #828282;

    /*
    &:not(.light) {
      background: #f0f2f5;
    }
    */

    &:disabled {
      background: #f0f2f5;
      border-color: #f0f2f5;
    }

    &-lg,
    &-affix-wrapper-lg {
      height: 45px;
      border-radius: 5px;
    }
  }
}
