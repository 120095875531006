@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
.page-registration {
  background: url('../../assets/mycent-login-image.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: end;
  align-items: center;

  @media screen and (max-width: 1100px) {
    padding: 20px;
    background: none;
    background-color: #DAE0EE;
  }

  /* Left column */

  &__content {
    max-width: 442px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: center;
    background-color: #fff;
    padding: 16px;
    border-radius: 32px;
    margin-right: 269px;
    &-inner {
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 1100px) {
      margin: 0 auto;
    }
  }

  /* Copyright */

  &__helpdesk-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  &__copyright {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__span {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
  }

  &__number {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    text-decoration: none;
  }

  /* Welcome title */
  &__welcome {
    width: 100%;
    height: 110px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
    border-radius: 16px;
    background: radial-gradient(50% 210.46% at 0% 100%, #002F99 0%, #004FFF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1400px) {
      font-size: 32px;
    }
  }

  /* Input */

  &__input {
    position: relative;
    border: 1px solid #CAD0DE;
    border-radius: 15px;
    height: 45px;
    background-color:#F1F5FE;
    .ant-input {
      background-color: #F1F5FE;
      background: none;
    }
  }

  &__input-wrap {
    position: relative;
    margin-bottom: 5px;
  }

  &__input-control {
    width: 100%;
    display: block;
    font-size: 18px;
    border-bottom: 1px solid #000;
    background-color: transparent;
    outline: none;
    padding: 3px 0;
    font-weight: 300;
    border: 0;

    &:hover {
      border-color: #777;
    }

    &:focus {
      border-color: #0057FF;
    }

    &--error {
      border-color: red;
    }
  }

  // Todo Переделать иконки, выпилить material ui
  &__password-icon {
    position: absolute !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__input-error-text {
    font-size: 14px;
    color: red;
    font-weight: 300;
  }

  /* Button submit */

  &__button {
    background-color: transparent;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 60px;
    padding: 0;
    border: 0;

    &:hover {
      color: #555;
    }
  }

  &__btn {
    background-color: #004FFF;
    width: 100%;
    height: 35px;
    border-radius: 8px !important;
  }

  &__title {
    color: #fff;
    font-weight: bold;
    font-size: 40px;
  }
}