@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
.page-login {
  background: rgb(221,227,239);
  background: linear-gradient(0deg, rgba(221,227,239,1) 0%, rgba(226,230,241,1) 60%, rgba(194,202,211,1) 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }

  &__rocket {
    background: url('../../assets/img/rocket.webp') 50% 50% no-repeat;
    width: 377px;
    height: 654px;
    margin-right: 200px;
    margin-left: -110px;
    margin-top: 20px;
    flex-shrink: 0;

    @media screen and (max-width: 1100px) {
      max-width: 277px;
      margin-right: 0;
      margin-left: -40px;
      height: 50dvh;
      background-size: contain;
    }

    @media screen and (max-width: 768px) {
      max-width: 180px;
      margin-left: -20px;
      margin-top: 20px;
    }

    @media screen and (max-width: 320px) {
      max-width: 150px;
    }
  }

  @media screen and (max-width: 1100px) {
    background: none;
    background-color: #DAE0EE;
  }

  /* Left column */
  &__content {
    max-width: 442px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: center;
    background-color: #fff;
    padding: 16px;
    border-radius: 32px;
    flex-shrink: 0;

    &-inner {
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: 1100px) {
      margin: 0 auto;
      max-width: 100%;
      border-radius: 32px  32px 0 0;
      height: 50dvh;
      justify-content: flex-start;
      text-align: center;
      padding: 16px 100px;
    }

    @media screen and (max-width: 768px) {
      padding: 16px;
    }
  }

  .ant-form-item-required {
    font-weight: 500 !important;
  }

  .ant-select-selector {
    padding-top: 5px !important;
    height: 45px !important;
    border-radius: 15px !important;
  }

  /* Copyright */
  &__helpdesk-content {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    @media (max-width: 1280px) {
      display: none;
    }
  }


  &__copyright {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    @media (max-width: 500px) {
      font-size: 12px;
      text-align: center;
    }
  }

  /* Welcome title */

  &__welcome {
    width: 100%;
    max-height: 110px;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 16px;
    background: radial-gradient(50% 210.46% at 0% 100%, #002F99 0%, #004FFF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    @media (max-width: 1400px) {
      font-size: 32px;
    }
  }

  &__logo {
    max-width: 122px;
    max-height: 50px;

    @media (max-width: 320px) {
      max-height: 40px;
    }
  }

  /* Input */
  &__input {
    position: relative;
    border: 1px solid #CAD0DE;
    border-radius: 15px;
    height: 45px;
    background-color:#F1F5FE;
    .ant-input {
      background-color: #F1F5FE;
      background: none;
      &::placeholder {
        text-align: center !important;
      }
    }
     &::placeholder {
      text-align: center !important;
      margin-right: 20px;
    }
  }

  @mixin input-logo($url, $x, $y) {
    background: url($url) no-repeat scroll 10px 10px;
    background-position-x: $x;
    background-position-y: $y;
    transition: none;
    @media (max-width: 1100px) {
      background: none;
    }
  }

  .input-user-logo {
    @include input-logo('../../assets/logo-user.svg', 20px, 13px);
  }

  .input-user-password {
    @include input-logo('../../assets/logo-password.svg', 20px, 12px);
  }

  &__icon {
    position: absolute;
    z-index: 2;
    left: 85px;
    top: 15px;
  }

  &__footer {
    background-color: #F1F5FE;
    width: 100%;
    padding: 8px;
    margin: 0;
    border-radius: 16px;
    .ant-form-item-control-input-content {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 40px;
    }
  }

  &__btn {
    background-color: #004FFF;
    width: 100%;
    height: 35px;
    border-radius: 8px !important;
  }

  &__btn-return {
    background: none;
    border: none;
  }

  // Todo Переделать иконки, выпилить material ui

  /* Right title */

  &__title {
    color: #fff;
    font-weight: bold;
    font-size: 40px;
  }

  &__helpdesk {

  }

  &__span {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }

  &__number {
    font-size: 16px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    text-decoration: none;

    @media (max-width: 500px) {
      font-size: 12px;
    }

    @media (max-width: 1280px) {
      color: white;
    }
  }
}


.tooltip-helpdesk {
  display: none;
  width: 90%;

  @media (max-width: 1280px) {
    display: block;
  }

  .ant-tooltip {
    top: 0 !important;
  }
}