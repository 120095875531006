.main-contract {
  h1 {
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  h3 {
    &.main-contract__section-title {
      color: #7449ef;
    }
  }

  &__role {
    background: #f8f8f8;
    padding: 25px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
  }
}
