.add-modal-online-library {
  .ant-modal-content {
    .delete-btn-online-library {
      border-radius: 16px;
      background-color: #d00000;
      color: #ffffff;
    }

    .reject-btn-online-library {
      border-radius: 16px;
      background-color: #dae0EE;
      color: #004fff;
    }
  }
}