.content-useful {
  background-color: #F1F5FE;
  min-height: 100vh;

  .container-useful {
    margin: 0 auto;
    padding: 10px;
    width: 95%;

    .path-category-useful {
      width: 50%;
    }

    .document-btn-useful,
    .add-folder-useful {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #e4572e;
      border-radius: 16px;
      color: #ffffff;
      padding: 20px 20px;
      font-weight: 600;
    }

    .back-btn-useful {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #dae0ee;
      border-radius: 16px;
      color: #e4572e;
      padding: 20px 20px;
      font-weight: 600;
    }

    .download-document-icon-useful {
      display: inline-block;
      background: url("../../assets/document-download-library.svg") center center no-repeat;
      background-size: contain;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    .search-input-useful {
      background: none;
      padding: 10px;
      border: 1px solid #cad0de;
      border-radius: 50px;
      color: #192749;
      font-size: 15px;

      .search-icon-useful {
        color: #cad0de;
        font-size: 20px;
      }

      .ant-input {
        background: none;
      }
    }

    .header-useful {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sector-useful {
      width: 100%;
      border: 1px solid #dae0ee;
      border-radius: 40px;
      padding: 30px 20px;
      background-color: #ffffff;
      margin-top: 20px;
    }
  }
}


