.user-details {
  .ant-steps-item {
    display: flex !important;

    &::before {
      content: attr(data-date-begin) ' \2014\a0 'attr(data-date-end);
      display: block;
      font-size: 14px;
      line-height: 32px;
      flex: 0 0 175px;
      color: #7449ef;
    }
  }

  .ant-steps-item-container {
    position: relative;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail:after {
    background: #7449ef;
  }

  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #7449ef;
  }

  .ant-collapse-content .ant-typography {
    font-weight: normal;
    color: #7449ef;
  }

  .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    padding-top: 5px;
  }
}
