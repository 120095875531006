.event-item-content {
  font-size: 16px;
  font-weight: normal;
  color: #474747;

  .check-icon {
    font-size: 22px;
    color: #2fa534;
  }

  .close-icon {
    font-size: 22px;
    color: #ff4d4f;
  }

  .host-icon {
    font-size: 22px;
    color: black;
  }

  &__title {
    font-size: 14px;
    color: #777;
    font-weight: 300;
    margin-bottom: 2px;

    &-color {
      color: #7449ef;
    }

    &-position {
      margin-bottom:45px;
    }

    &-text {
      margin-top: 35px;
      color: #000000;
    }

    &-participants {
      margin-bottom: 15px;
    }
  }

  .ant-statistic-content {
    font-size: 16px;
    color: #7449ef;
  }

  &__start {
    display: flex;

    &-column {
      flex: 1 1 50%;
      width: 100%;
      max-width: 50%;
    }

    &-timer {
      color: #7449ef;
      display: block;
    }
  }

  &__users-list {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 -25px;
    display: flex;
    flex-wrap: wrap;

    &-item {
      flex: 0 0 25%;
      width: 100%;
      max-width: 25%;
      margin-bottom: 25px;
    }

    &-inner {
      margin-left: 25px;
      display: flex;
      align-items: center;
    }

    &-img {
      background-color: #ccc;
      background-image: url("./Ellipse_3.png");
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }

    &-upload {
      margin-top: 10px;
      border-radius: 50%;
      width: 50px;
      height: 30px;
      font-size: 28px;
    }

    &-file {
      background-color: #ccc;
      border-radius: 30%;
      width: 30px;
    }

    &-title {
      font-size: 14px;
      line-height: 1.1;
      margin-left: -10px;
      // margin-top: 10px;

      span {
        display: block;
      }
    }
  }

  &__description {
    background-color: #fff;
    margin-top: 12px;
    padding: 5px;
    margin-bottom: 20px;

    &-size {
      padding: 12px 20px;
      box-sizing: border-box;
      width: 525px;
    }
  }
}

.page-header {
  &__author {
    display: flex;
    width: 50%;
    
    &-avatar {
      display: flex;
      justify-content: center; /* centers images */
      flex: 1; /* enlarge each element equally to its maximum */
      padding: 10px;
    

    &-img {
      width: 50px; height: 50px;
      background-image: url("./Ellipse_3.png");
    }
  
      &-info {
        span {
          display: block;
        }
      }
    }
  }
}

.icon-location {
  margin-top: -20px;
}

.position-document {
  margin-top: -13px;
  margin-left: 35px;
  width: 100px;
}