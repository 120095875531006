.publication {
  background: #ffffff;
  min-height: 84px;
  /* box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06); */
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 0 40px 0;
  font-weight: normal;
}
.publication_image {
  margin-top: 20px;
  /* box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.12); */
}

.publication_text {
  border: 1px solid transparent !important;
  min-height: 70px !important;
  width: 62.5%;
  overflow: hidden;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  left: 41.5%;
  transform: translate(-50%, 0);
  resize: none;
  /*position:absolute;*/
  white-space: pre-wrap;
}

.publication_text:focus {
  box-shadow: 0 0 0 1px transparent;
}

.delete_attach {
  position: relative;
  display: inline-block;
}
.delete_attached_file {
  position: relative;
  /*display: block;*/
  display: flex;
  font-size: 14px;
}
.delete_image {
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 25px;
  color: white;
  background-color: #7449ef;
}
.delete_video {
  position: absolute;
  right: 0;
  font-size: 25px;
  color: white;
  background-color: #7449ef;
}
.delete_file {
  position: absolute;
  right: 0;
  font-size: 25px;
  color: white;
  background-color: #7449ef;
}

.comment_section {
  border: 1px solid transparent !important;
  min-height: 52px !important;
  /*width: 835px;*/
  white-space: pre-wrap;
  width: 94%;
  overflow: hidden;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 200px;
  background: #f0f2f5;
  resize: none;
  margin-bottom: 65px;
  left: 30px;
  border-radius: 5px;
}

.reply_comment_section {
  border: 1px solid transparent !important;
  min-height: 42px !important;
  /*width: 805px;*/
  width: 90.8%;
  overflow: hidden;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  resize: none;
  border-radius: 5px;
  left: 92px;
  padding-top: 5px;
  padding-left: 30px;
  padding-right: 200px;
  background: #f0f2f5;
}

.reply_section_icon {
  width: 37px !important;
  height: 37px !important;
  position: absolute !important;
  left: 46px;
  top: 0;
}

.edit_comment_section {
  border: 1px solid transparent !important;
  overflow: hidden;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  background: #ffffff;
  resize: none;
}

.comment_section_icon {
  width: 37px !important;
  height: 37px !important;
  position: absolute !important;
  left: 0;
  top: 8px;
}

.comment_divider {
  margin-bottom: 46px !important;
  border: 1px solid #c4c4c4;
  width: 900px;
  height: 0px;
}

.comment_text {
  padding-left: 23px;
  padding-top: 8px;
  min-height: 102px !important;
  margin-left: 46px !important;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 97%;
  outline: none;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  background: #f0f2f5;
  text-align: left;
}

.rep_comment_text {
  padding-left: 23px;
  padding-top: 8px;
  min-height: 102px !important;
  margin-left: 46px !important;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 96.8%;
  outline: none;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  background: #f0f2f5;
  text-align: left;
}

.comment_icon {
  width: 37px !important;
  height: 37px !important;
  position: absolute !important;
}

.comment_name {
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  font-weight: normal;
  display: block;
  padding-bottom: 10px;
}

.comment_content {
  text-align: left;
  color: #000000;
  white-space: pre-wrap;
}

.show_comments {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #7449ef;
  text-align: left;
  display: inline-block;
  margin-bottom: 32px;
}

.first_reply {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-left: 46px !important;
  margin-bottom: 28px !important;
  display: inline-block;
}

.show_replies {
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  color: #7449ef;
  margin-top: 16px;
  margin-left: 125px;
  margin-bottom: 50px;
}

.show_more {
  text-align: center;
  margin-bottom: 15px;
  background-color: #815ced;
}

.show_more:hover {
  background-color: #a4b3b0;
}

.comment_like {
  position: absolute;
  right: 20px;
  bottom: 65px;
  font-size: 15px;
  display: inline-flex;
  margin-right: 13px;
  color: #815ced;
  cursor: pointer;
}

.comment_like_count {
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-right: 10px;
  position: absolute;
  right: 10px;
  bottom: 62px;
}

.comment_option {
  position: absolute;
  top: 5px;
  color: #c4c4c4;
}

.comment_option:hover {
  transition: all 0.1s ease;
  color: #7449ef !important;
}

.comment_edit {
  right: 60px;
}

.comment_check {
  right: 30px;
}

.comment_delete {
  right: 5px;
}

/* Todo переписать комменты */
.comment_meta {
  padding: 25px 20px 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment_meta .button-like {
  min-width: auto;
}
.comment_meta .button-like__button {
  font-size: 20px;
}

.comment_date {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #949494;
  display: block;
  /* margin-top: 25px; */
}

/* .publisher_icon {
  position: absolute !important;
  width: 45px !important;
  height: 43px !important;
  left: 6%;
  top: 42px;
  transform: translate(-50%, -50%);
} */

.big-icon {
  font-size: 28px !important;
  position: absolute;
  cursor: pointer;
  color: #6c6c6c;
}
.big-icon :hover {
  color: #7449ef;
}
.big-icon :checked {
  color: #7449ef;
}
.picture_attach {
  /*position: absolute;*/
}
.comment_icons {
  font-size: 20px !important;
  position: absolute;
  cursor: pointer;
  color: #494949;
  top: 18px;
}
.reply_comment_icons {
  font-size: 20px !important;
  position: absolute;
  cursor: pointer;
  color: #494949;
  top: 12px;
}
.comment_smile {
  right: 152px;
}

.comment_picture {
  right: 108px;
}

.comment_post {
  font-size: 30px !important;
  position: absolute;
  cursor: pointer;
  color: #494949;
  top: 12px;
  right: 22px;
}
.reply_comment_post {
  font-size: 30px !important;
  position: absolute;
  cursor: pointer;
  color: #494949;
  top: 6px;
  right: 22px;
}
.ver_divider {
  position: absolute;
  right: 80px;
  top: 4px;
  border: 1px solid #595959;
  height: 45px;
}
.reply_ver_divider {
  position: absolute;
  right: 80px;
  top: 4px;
  border: 1px solid #595959;
  height: 36px;
}

.picture_icon {
  right: 133px;
  top: 75px;
}
.video_icon {
  right: 230px;
  top: 75px;
}

.folder_icon {
  right: 84px;
  top: 75px;
}
.smile_icon {
  right: 181px;
  top: 75px;
  font-weight: 300;
}
.post_icon {
  right: 36px;
  top: 75px;
}

.news-sort {
  margin-right: 13px;
}

.sorting {
  margin-right: 13px;
}
.search_down {
  color: #000000 !important;
  padding-top: 13px;
  font-size: 13px !important;
  /*margin-left: 13px !important;*/
}

.heart_count {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
