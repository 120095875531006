.c-files{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &-info{
        display: flex;
        gap: 8px;
        align-items: center;
        p{
            color: #7449EF;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 5px;
        }
        button{
            height: 35px;
            width: 35px;
            background: transparent;
            border: 1px solid #7449EF;
            color: #7449EF;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &-action{
        height: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        img{
            width: 100%;
            cursor: pointer;
        }
    }
}